import { graphql } from "gatsby"
import Page from "./page"

const NutritionPage = Page

export const query = graphql`
  query nutritionPageById($id: String) {
    page: datoCmsNutritionPage(id: { eq: $id }) {
      id
      slug
      title
      banner {
        gatsbyImageData(imgixParams: { w: "1280", h: "640", fit: "crop" })
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsVideo {
          id
          model {
            apiKey
          }
          videoUrl {
            url
          }
        }
        ... on DatoCmsImage {
          id
          model {
            apiKey
          }
          image {
            alt
            gatsbyImageData(
              imgixParams: { w: "1280", h: "640", fit: "crop", auto: "true" }
            )
          }
        }
        ... on DatoCmsContent {
          id
          model {
            apiKey
          }
          contentNode {
            id
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsBlockquote {
          id
          model {
            apiKey
          }
          quoteNode {
            id
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default NutritionPage
